import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "../styles/Voice.module.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { RecruitLayout,RecruitLowerHeaderBg,RecruitEntry } from "../components/Recruit/index";
import { LowerHeader } from "../components/LowerPages/index"

const Voice = ({ data }) => {
  const page = data.allWpRecruitVoice.edges
  // const acf = page.RecruitVoiceField
  // console.log(page[0].node.featuredImage)

  const breakpoints = useBreakpoint();
  const title1 = [
    { "id": 0, "text": "v" },
    { "id": 1, "text": "o" },
    { "id": 2, "text": "i" },
    { "id": 3, "text": "c" },
    { "id": 4, "text": "e" },
    { "id": 5, "text": "" },
    { "id": 6, "text": "l" },
    { "id": 7, "text": "i" },
    { "id": 8, "text": "s" },
    { "id": 9, "text": "t" }
  ];
  return (
    <RecruitLayout className={`${styles.Voice}`} pageName={`voice`} title={`先輩たちの声 一覧`}>
      <Seo
        title={`先輩たちの声 一覧`}
        description={``}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/voice/voice_header-sp@2x.png"
              alt="news_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/voice/voice_header@2x.png"
              alt="news_header"
            />

        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`先輩たちの声 一覧`}>
          <RecruitLowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div>
                <ul className={styles.Voice__list}>
                {page.map(({ node }) => (
                  <li key={node.title} className={`${styles.Voice__listItem}`}>
                    <a href={`/voice/${node.databaseId}/`}>
                      <div className={`${styles.Voice__listImg}`}>
                        <span>
                          <picture>
                            <source srcSet={node.featuredImage.node.localFile.childImageSharp.fluid.srcSetWebp} />
                            <img src={node.featuredImage.node.localFile.childImageSharp.fluid.src} alt={node.title} />
                          </picture>
                        </span>
                        <span className={`${styles.Voice__listImgJoin}`}>{node.RecruitVoiceField.joindate}</span>
                      </div>
                      <h3 className={`${styles.Voice__listTitle}`}>{node.title}</h3>
                      <div className={`${styles.Voice__listDep}`}>
                        <span>{node.RecruitVoiceField.department}</span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
          </div>
          <section>
            <RecruitEntry/>
          </section>
        </div>
      </div>
    </RecruitLayout>
  )
}
export default Voice

export const query = graphql`
 query {
   site {
     siteMetadata {
       title
       siteUrl
     }
   }
  allWpRecruitVoice (sort: { fields: [modified] , order: DESC}){
    edges {
      node {
        title
        slug
        id
        link
        guid
        date(formatString: "YYYY.MM.DD")
        modified(formatString: "YYYY/MM/DD")
        desiredSlug
        databaseId
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcSetWebp
                }
                gatsbyImageData
              }
            }
          }
        }
        RecruitVoiceField {
          department
          fieldGroupName
          joindate
        }
      }
    }
  }
 }
`